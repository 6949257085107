@import "../variables";
@import "../mixins";

.tx-pb-social .pb-list {
    margin-left: -1rem;
    margin-bottom: -1rem;
}

//.tx-pb-social .pb-list .col {
//	float: left;
//	width: 250px;
//}

.tx-pb-social .pb-list-item-wrap {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.pb-list .pb-list-item-wrap:nth-child(n+1){
    margin-right: -4px;
}

@media only screen and (max-width: 1024px) {
    .tx-pb-social .pb-list-item-wrap {
        width: 33.3333%;
    }
}

@media only screen and (max-width: 600px) {
    .tx-pb-social .pb-list-item-wrap {
        width: 50%;
    }
}

@media only screen and (max-width: 480px) {
    .tx-pb-social .pb-list-item-wrap {
        width: 100%;
    }
}

.tx-pb-social .pb-list-item {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.tx-pb-social .pb-list-item .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.tx-pb-social .pb-list-item .icon {
    position: absolute;
    top: 30px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    cursor: pointer;
    background-size: 40px 40px;
}

.tx-pb-social .pb-list-item .text-wrap {
    position: absolute;
    height: 25%;
    min-height: 120px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    cursor: pointer;
}

.tx-pb-social .pb-list-item .text-wrap .text {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    color: #000;
    font-size: 16px;
    line-height: 1.6;
    overflow: hidden;
}

.tx-pb-social .pb-list-item .additional-info {
    box-sizing: border-box;
    position: absolute;
    color: #ffffff;
    width: 30px;
    height: 20px;
    bottom: 2px;
    text-align: right;
    text-decoration: none;
    font-size: 11px;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

.tx-pb-social .pb-list-item .additional-info.info-1 {
    right: 65px;
}

.tx-pb-social .pb-list-item .additional-info.info-2 {
    right: 20px;
}

.tx-pb-social .pb-list-item .video-overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    opacity: 0.9;
    cursor: pointer;
}

.tx-pb-social .pb-list-item[data-type="video"] .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
}

.tx-pb-social .pb-list-item[data-type="video"] .video-overlay {
    top: 95px;
    bottom: auto;
}

.tx-pb-social .pb-list-item-facebook .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/fb.jpg");
}

.tx-pb-social .pb-list-item-facebook .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook.png");
}

.tx-pb-social .pb-list-item-googleplus .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/gplus.jpg");
}

.tx-pb-social .pb-list-item-googleplus .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/google+.png");
}

.tx-pb-social .pb-list-item-instagram .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/instagram.png");
}

.tx-pb-social .pb-list-item-pinterest .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/pinterest.png");
}

.tx-pb-social .pb-list-item-tumblr .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/tumblr.jpg");
}

.tx-pb-social .pb-list-item-tumblr .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/tumblr.png");
}

.tx-pb-social .pb-list-item-twitter .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/twitter.jpg");
}

.tx-pb-social .pb-list-item-twitter .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/twitter.png");
}

.tx-pb-social .pb-list-item-imgur .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/imgur.png");
}

.tx-pb-social .pb-list-item-youtube .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/yt.jpg");
}

.tx-pb-social .pb-list-item-youtube .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/youtube.png");
}

.tx-pb-social .pb-list-item-vimeo .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/vimeo.png");
}

.tx-pb-social .pb-list-item-vimeo .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/vimeo.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-detail-wrap {
    position: relative;
    top: 190px;
    text-align: center;
    width: 100%;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details {
    opacity: 0;
    position: relative;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.75);
    color: white;
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details.active {
    opacity: 1;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details span {
    position: relative;
    top: 10px;
    margin: 0 4px 0 5px;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details i {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 3px;
    background-size: 12px 12px;
    background-size: cover;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__like {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/like.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__love {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/love.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__haha {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/haha.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__wow {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/wow.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__sad {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/sad.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__angry {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/angry.png");
}

.tx-pb-social .button {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    height: 30px;
    width: 300px;
    padding-top: 15px;
    border: 1px solid black;
}

.tx-pb-social .button.loading {
    border: none;
    color: white;
    background-color: darkgray;
    cursor: progress;
}

.tx-pb-social .button.pb-disabled {
    visibility: hidden;
}

.tx-pb-social .button__wrap {
    float: left;
    width: 100%;
    text-align: center;
}

.tx-pb-social .pb-hide-initial {
    display: none;
}
