$actSelector: "html.header-overlay-active";
$inactSelector: "html.header-overlay-inactive";

@keyframes header-overlay-active {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes header-overlay-inactive {
    from {
        top: 0;
        opacity: 1;
    }

    99% {
        top: 0;
    }

    to {
        opacity: 0;
        top: -100%;
    }
}

#c268 {
    display: none;
}

#{$actSelector} {
    #c268 {
        position: fixed;
        z-index: 2000;
        display: initial;
        left: 50%;
        transform: translateX(-923px);
        top: 10%;
        @include header-middle-font-and-size();
        text-transform: uppercase;

        @media screen and (max-width: 2000px) {
            left: 4rem;
            transform: initial;
        }

        li {
            margin-top: 30px;
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: rgba(255,255,255,0.7);
            }
        }
    }
}

#header {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;


    &__toggle {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(876px);
        width: 41px;
        z-index: 102;

        @media screen and (max-width: 2000px){
            left: initial;
            transform: initial;
            right: 3.8rem;
        }

        #menu-sign {
            position: absolute;
            right: 30px;
            text-transform: uppercase;
            color: $color-highlight;
            right: 55px;
            top: 50%;
            transform: translateY(-50%);
        }

        &__button {
            position: absolute;
            width: 41px;
            height: 41px;
            //background-color: rgba(red, 0.5);
            top: 50px;

            .bars {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 41px;
                height: 3px;
                background-color: #fff;
                transition: width 0.3s ease-in-out;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 41px;
                    height: 3px;
                    background-color: #fff;
                    transition: all 0.3s ease-in-out;
                }

                &:before {
                    top: -7px;
                    left: 5px;
                }

                &:after {
                    top: 7px;
                    left: 5px
                }
            }

            // state of button when overlay is active - transforms to a X
            #{$actSelector} & {

                #menu-sign {
                    color: white;
                }

                .whitediv {
                    width: 100%;
                    height: 200px;
                    background-color:red;
                }

                .bars {
                    width: 0;

                    &:before,
                    &:after {
                        left: -20px;
                        top: 0;
                        width: 35px;
                        background-color: #fff;
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    &__overlay {
    

        z-index: 101;
        position: fixed;
        top: -100%;
        opacity: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: $color-highlight;
        background-image: url("/typo3conf/ext/app/Resources/Public/gfx/backgrounds/black_triangle.svg");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 60vw;

        .overlaycontainer {
            height: 100%;
        }


        .links {
            a {
               text-decoration: none; 
            }
            
        }



        #{$actSelector} & {
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: header-overlay-active;
            animation-fill-mode: forwards;
            top: 0;
        }

        #{$inactSelector} & {
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: header-overlay-inactive;
            animation-fill-mode: forwards;
        }
    }
}

@media screen and (max-width: 1500px) {
    #{$actSelector} {
        #c268 {
            li {
                margin-top: 20px;
                font-size: 50px;
            }
        }
    }
}

@include mediaQuery($device_smaller_than_desktop) {

    #header {

        &__logo {
            width: 250px;
            height: 60px;
        }

        &__toggle {
            margin-left: 32px;
        }
    }
}

@include mediaQuery($device_laptop) {
    #{$actSelector} {
        #c268 {
            left: 2rem;
            transform: initial;
        }
    }

    #header {

        &__logo {
            left: 2rem;
            top: 23px;
        }

        &__toggle {
            top: -30px;
            right: 1.8rem;
        }

        &__overlay {
            background-size: 60vw;
        }
    }
}

@include mediaQuery($device_smaller_than_laptop) {
    #{$actSelector} {
        #c268 {
            left: 2rem;
            transform: initial;

            li {
                font-size: 80%;
            }
        }
    }

    #header {
        &__logo {
            left: 2rem;
            top: 23px;
        }

        &__toggle {
            top: -30px;
            right: 1.8rem;

            &__button {
                top: 67px;
                transform: translate(-50%, -50%);
            }
        }

        &__overlay {

            background-size: 80vw;

            &__logo {
                top: 23px;
            }
        }
    }
}

@include mediaQuery($device_smartphone) {
    #{$actSelector} {
        #c268 {
            left: 1rem;
            transform: initial;
        }
    }

    #header {
        &__logo {
            left: 1rem;
            top: 23px;
            width: 150px;
            height: 35px;
        }

        &__toggle {
            margin-left: 16px;
            height: auto;
            right: 0;
        }

        &__overlay {

            background-size: 180vw;

            &__logo {
                top: 23px;
            }

            &__socials {
                margin-left: 16px;
            }
        }
    }
}

@media screen and (max-width: 355px) {
    #{$actSelector} {
        #c268 {

            li {
                font-size: 70%;
            }
        }
    }
}
