/* hind-700 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fonts-directory}hind-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Hind Bold'), local('Hind-Bold'),
       url('#{$fonts-directory}hind-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}hind-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}hind-v10-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}hind-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}hind-v10-latin-700.svg#Hind') format('svg'); /* Legacy iOS */
}


/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fonts-directory}assistant-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Assistant'), local('Assistant-Regular'),
       url('#{$fonts-directory}assistant-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}assistant-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}assistant-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}assistant-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}assistant-v4-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}

/* assistant-700 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fonts-directory}assistant-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Assistant Bold'), local('Assistant-Bold'),
       url('#{$fonts-directory}assistant-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}assistant-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}assistant-v4-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}assistant-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}assistant-v4-latin-700.svg#Assistant') format('svg'); /* Legacy iOS */
}