nav.meta {
    position: absolute;
    bottom: 10%;
    padding-top: 3rem;
    padding-left: 4rem;

    @include mediaQuery($device_smaller_than_laptop) {
        padding-left: 2rem;
        padding-top: 3vw;
    }

    @include mediaQuery($device_smartphone) {
        padding-left: 1rem;
        bottom: 30px;
    }

    ul, li, a {
        display: inline-block;
    }

    li {

        &:first-child {
            margin-left: 0;
            margin-right: 20px;

            &:before {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: $font-weight-regular;

        #{$no-touch-selector} &:hover,
        &.active {
            color: rgba(255,255,255,0.7);
        }
    }
}