#footer {
    padding-bottom: 0.5rem;

    #certificates {
        background-color: black;
        padding: 0.5rem 0;
        position: relative;
        height: 300px;
        margin-bottom: 60px;

        &__weld, &__master {
            width: 90px;
            margin-bottom: 20px;
        }

        &__one, &__two {
            display: inline-block;
            position: absolute;
            top: 50%;
            width: 25%;
            text-align: center;

            a {
                text-decoration: none;
            }

            a:hover {
                opacity: 0.7;
                transition: opacity 0.3s ease-in-out;
                text-decoration: none;
            }
        }

        &__one {
            right: 50%;
            transform: translatex(-50px) translateY(-50%);
        }

        &__two {
            left: 50%;
            transform: translatex(50px) translateY(-50%);
        }
    }

    .footercontent {
        padding: 35px 64px;
    }

    .address {
        width: 33.3%;
        display: block;
        vertical-align: top;
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;

        a {
            font-family: $font-family;
            font-weight: $font-weight-regular;
            text-decoration: none;
        }
    }

    .logocontainer {
        display: inline-block;
        width: 33.3%;
    
        .logos {
            display: flex;
            vertical-align: middle;

            .logo-first, .logo-second {
                width: 50%;
                display: flex; 
                justify-content: center; 
                align-items: center;


                img {
                    max-height: 50px;
                    width: auto;
                }
            }
        }
    }

    .upperborder {
        border-top: 1px solid #000;
        display: inline;
        padding-top: 0.75rem;
    }

    &__logo {
        display: block;
        width: 430px;
        height: 120px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}

@include mediaQuery($device_smaller_than_desktop){

    #footer {
    
        .footercontent {
            padding: 35px 32px;
        }
    
        .address {
            width: 100%;
            padding-bottom: 30px;
        }
    
        .morelinks {
            width: 50%;
    
            ul {
                padding-left: 0px;
            }
    
            .morelinks-first{
                margin-left: 0px;
            }
        }
    
        .logocontainer {
            width: 50%;
        }
    }
}

@include mediaQuery($device_smaller_than_laptop) {
    #footer {
        &__logo {
            width: 260px;
        }

        #certificates {
            margin-bottom: 30px;
            height: initial;
    
            &__weld, &__master {
                width: 90px;
                margin-bottom: 12px;
            }
    
            &__one, &__two {
                display: block;
                position: initial;
                width: 100%;
                text-align: center;
                margin: 25px 0;
            }
    
            &__one {
                right: initial;
                transform: initial;
            }
    
            &__two {
                left: initial;
                transform: initial;
            }
        }
    }
}

@include mediaQuery($device_smartphone){

    #footer {

        &__logo {
            width: 85%;
        }
    
        .footercontent {
            padding: 35px 0px;
        }
    
        .address {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;

            .companyname {
                display: block;
            }
        }
    
        .mailaddress {
            display: block;
        }

        .morelinks {
            width: 100%;
            padding: 15px 16px 0;
            margin-bottom: 15px;
            background-color: rgba(235, 235, 235, 0.6);
            
    
            ul {
                padding-left: 0px;
                line-height: 2.3;
            }
    
            .morelinks-first{
                margin-right: 30%;
            }
        }
    
        .logocontainer {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;

            .logos {

                .logo-first, .logo-second{

                    img {
                        width: 100%;
                        max-height: initial;
                    }

                }
            }
        }
    }
}


