//html.page-layout-1 #body {
//    margin-top: 200px;
//}

body {
    scroll-behavior: smooth;
}

#body {
    padding-bottom: 0;
}

.closebutton {
    z-index: 2000;
    position: fixed;
    width: 35px;
    height: 35px;
    left: 50%;
    transform: translateX(840px);
    top: 50px;
    background-image: url("/typo3conf/ext/app/Resources/Public/gfx/icons/close.svg");
    background-repeat: no-repeat;

    @media screen and (max-width: 2000px) {
        transform: initial;
        left: initial;
        right: 4rem;
    }

}

@include mediaQuery($device_laptop) {
    .closebutton {
        right: 2rem;
    }
}

@include mediaQuery($device_tablet) {
    .closebutton {
        right: 2rem;
        width: 25px;
        height: 25px;
    }
}

@include mediaQuery($device_smartphone) {
    .closebutton {
        top: 30px;
        right: 1rem;
        width: 25px;
        height: 25px;
    }
}